// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { NotificationManager } from "react-notifications";
// import { GetUserLogin, GetOrderDetails, CartHelper } from "../../../services";
// import {
//   removeFromCart,
//   incrementToCart,
//   decreaseToCart,
// } from "../../../../store/actions/cartActions";
// import Deliverydetails from "./delivery";
// import Loader from "../../../../loader";

// class Checkout extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoaded: false,
//       subTotal: 0,
//       discount: 0,
//       deliveryCharge: 0,
//       grandTotal: 0,
//       email: "",
//       customer: "",
//       deliveryAddress: "",
//       currentStep: 1,
//     };
//   }

//   handleRadioChange = (e) => {
//     this.setState({ [e.target.name]: e.target.value });
//   };

//   handleDeliveryAddress = (value) => {
//     this.setState({ deliveryAddress: value, currentStep: 3 }); // Move to payment step after selecting delivery address
//   };

//   async componentDidMount() {
//     let email = sessionStorage.getItem("email");
//     if (email) {
//       let user = await GetUserLogin.getCustomerDetail(email);
//       if (user) {
//         this.setState({ customer: user.data, email: email, currentStep: 2 }); // Open delivery section after login
//       }
//     }

//     let cart = this.props.cartItems;
//     let subTotal = this.calculateSubTotal();
//     let discount = subTotal > 200 ? subTotal * 0.1 : 0; // 10% discount if subtotal is greater than 200
//     let deliveryCharge = subTotal * 0.05;
//     let grandTotal = subTotal - discount + deliveryCharge;

//     this.setState({ subTotal, discount, deliveryCharge, grandTotal });
//   }


//   handlePaymentSystem = async (e) => {
//     e.preventDefault();
//     this.setState({ isLoaded: true }); 

//     const { customer, deliveryAddress } = this.state;
//     const { cartItems } = this.props;

   
//     const orderId = Math.floor(Math.random() * Date.now());

//     if (deliveryAddress) {
//       const grandTotal = this.calculateGrandTotal(cartItems);

  
//       const products = this.prepareProducts(cartItems);

//       const addressId = deliveryAddress.selectedAddress || null;

     
//       const preorderData = {
//         customerId: customer.id,
//         orderId: orderId,
//         grandTotal: grandTotal,
//         products: products,
//       };

//       try {
//         await this.createPreorder(preorderData);

     
//         const options = this.getRazorpayOptions(
//           grandTotal,
//           customer,
//           orderId,
//           addressId,
//           deliveryAddress,
//           products
//         );


//         const paymentObject = new window.Razorpay(options);
//         paymentObject.open();

   
//         paymentObject.on("close", () => {
//           this.setState({ isLoaded: false }); 
//         });
//       } catch (err) {
//         console.error("Error creating preorder:", err);
//         NotificationManager.error("Preorder creation failed", "Error");
//         this.setState({ isLoaded: false }); 
//       }
//     } else {
//       NotificationManager.error("Please check address details", "Input Field");
//       this.setState({ isLoaded: false }); 
//     }
//   };


//   calculateGrandTotal = (cartItems) => {
//     const subTotal = this.calculateSubTotal(cartItems);
//     const discount = subTotal > 200 ? subTotal * 0.1 : 0;
//     const deliveryCharge = 0;
//     return subTotal - discount + deliveryCharge;
//   };


//   prepareProducts = (cartItems) => {
//     return cartItems.map((item) => {
//       const variantIds = item.selectedVariants.map((variant) => variant.id);
//       return {
//         productId: item.id,
//         variantIds: variantIds,
//       };
//     });
//   };


//   createPreorder = async (preorderData) => {
//     return await GetOrderDetails.createPreorder(preorderData);
//   };

 
//   getRazorpayOptions = (
//     grandTotal,
//     customer,
//     orderId,
//     addressId,
//     deliveryAddress,
//     products
//   ) => {
//     return {
//       key: "rzp_live_YJ7vOnVL1HKfgL",
//       amount: grandTotal * 100,
//       currency: "INR",
//       name: "JC Creations",
//       description: "JC Creations",
//       image: "img/footerlogo.webp",
//       prefill: {
//         name: customer.fullName, 
//         email: customer.email,
//         contact: customer.phone || '', 
//       },
//       theme: {
//         color: "#F37254", 
//       },
//       handler: async (response) => {
//         if (response.razorpay_payment_id) {
//           await this.handlePaymentCapture(
//             response.razorpay_payment_id,
//             grandTotal,
//             customer,
//             orderId,
//             addressId,
//             deliveryAddress,
//             products
//           );
//         }
//       },
//       modal: {
//         ondismiss: () => {
//           // Stop the loader if payment modal is closed
//           this.setState({ isLoaded: false });
//         },
//       },
//     };
//   };


//   // handlePaymentCapture = async (
//   //   razorpayPaymentId,
//   //   grandTotal,
//   //   customer,
//   //   orderId,
//   //   addressId,
//   //   deliveryAddress,
//   //   products
//   // ) => {
//   //   try {
//   //     const Paymentdata = { razorpayPaymentId, grandTotal };
//   //     const captureResponse = await GetOrderDetails.capturePayment(Paymentdata);

//   //     if (captureResponse.success) {
//   //       // Step 2: Payment was captured, now create the order
//   //       const orderData = {
//   //         customerId: customer.id,
//   //         orderId: orderId,
//   //         addressId: addressId,
//   //         deliveryAddress: deliveryAddress,
//   //         grandTotal: grandTotal,
//   //         razorpayPaymentId: razorpayPaymentId,
//   //         products: products,
//   //       };

//   //       let order = await GetOrderDetails.getOrderCreateByUser(orderData);
//   //       if (order) {
//   //         NotificationManager.success("Successfully Ordered", "Order");
//   //         setTimeout(() => {
//   //           CartHelper.emptyCart();
//   //         }, 1000);
//   //       } else {
//   //         window.location.href = "/order/failed";
//   //       }
//   //     } else {
//   //       NotificationManager.error(
//   //         "Payment failed. Please try again.",
//   //         "Payment"
//   //       );
//   //     }
//   //   } catch (err) {
//   //     console.error("Error during payment capture:", err);
//   //     NotificationManager.error("Order creation failed", "Error");
//   //   } finally {
//   //     this.setState({ isLoaded: false }); // Stop the loader in both success and failure
//   //   }
//   // };


//   handlePaymentCapture = async (
//     razorpayPaymentId,
//     grandTotal,
//     customer,
//     orderId,
//     addressId,
//     deliveryAddress,
//     products
//   ) => {
//     try {
//       const Paymentdata = { razorpayPaymentId, grandTotal };
  
//       // Step 1: Attempt to capture payment, but proceed regardless of the result
//       try {
//         await GetOrderDetails.capturePayment(Paymentdata);
//       } catch (captureError) {
//         console.warn("Payment capture failed, proceeding with order creation:", captureError);
//       }
  
//       // Step 2: Create the order
//       const orderData = {
//         customerId: customer.id,
//         orderId: orderId,
//         addressId: addressId,
//         deliveryAddress: deliveryAddress,
//         grandTotal: grandTotal,
//         razorpayPaymentId: razorpayPaymentId,
//         products: products,
//       };
  
//       let order = await GetOrderDetails.getOrderCreateByUser(orderData);
  
//       if (order) {
//         NotificationManager.success("Successfully Ordered", "Order");
//         setTimeout(() => {
//           CartHelper.emptyCart();
//         }, 1000);
//       } else {
//         window.location.href = "/order/failed";
//       }
  
//     } catch (err) {
//       console.error("Error during payment or order creation:", err);
//       NotificationManager.error("Order creation failed", "Error");
//     } finally {
//       this.setState({ isLoaded: false }); // Stop the loader in both success and failure
//     }
//   };
  

//   handleRemoveProduct = (productId) => {
//     this.props.removeFromCart(productId, null);
//   };

//   calculateSubTotal = () => {
//     return this.props.cartItems.reduce((sum, item) => {
//       if (Array.isArray(item.selectedVariants)) {
//         return (
//           sum +
//           item.selectedVariants.reduce((variantSum, variant) => {
//             return (
//               variantSum +
//               (typeof variant.price === "number" ? variant.price : 0)
//             );
//           }, 0)
//         );
//       }
//       return sum;
//     }, 0);
//   };

//   render() {
//     const { cartItems } = this.props;
//     const { discount, email, customer, currentStep, isLoaded } = this.state;

//     const subTotal = this.calculateSubTotal();
//     const deliveryCharges = subTotal * 0.0;
//     const total = subTotal - discount + deliveryCharges;

//     return (
//       <div>
//         <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-12">
//                 <a href="/">
//                   <strong>
//                     <span className="mdi mdi-home"></span> Home
//                   </strong>
//                 </a>{" "}
//                 <span className="mdi mdi-chevron-right"></span> <a>Checkout</a>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="checkout-page section-padding">
//           <div className="container">
//             {isLoaded ? <Loader /> : ""}
//             <div className="row">
//               <div className="col-md-8">
//                 <div className="checkout-step">
//                   <div className="accordion" id="accordionExample">
//                     <div className="card checkout-step-one">
//                       <div className="card-header" id="headingOne">
//                         <h5 className="mb-0">
//                           <button
//                             className="btn btn-link checkout-login-bk"
//                             disabled
//                           >
//                             <span className="number">1</span> Login{" "}
//                             <span className="mdi mdi-checkbox-marked-circle-outline"></span>
//                           </button>
//                           <div className="_2jDL7w">
//                             <div>
//                               <span className="dNZmcB">
//                                 {customer.firstName}
//                               </span>
//                               <span className="_3MeY5j">{email}</span>
//                             </div>
//                           </div>
//                         </h5>
//                       </div>
//                     </div>

//                     {/* Step 2: Delivery Address */}
//                     {currentStep >= 2 && (
//                       <div className="card checkout-step-two">
//                         <div className="card-header" id="headingTwo">
//                           <h5 className="mb-0">
//                             <button
//                               className="btn btn-link collapsed"
//                               type="button"
//                               data-toggle="collapse"
//                               data-target="#collapseTwo"
//                               aria-expanded="false"
//                               aria-controls="collapseTwo"
//                             >
//                               <span className="number">2</span> Billing Address
//                             </button>
//                           </h5>
//                         </div>
//                         <div
//                           id="collapseTwo"
//                           className="collapse show"
//                           aria-labelledby="headingTwo"
//                           data-parent="#accordionExample"
//                         >
//                           <Deliverydetails
//                             onSelectDeliveryAddress={this.handleDeliveryAddress}
//                           />
//                         </div>
//                       </div>
//                     )}

//                     {/* Step 3: Payment */}
//                     {currentStep >= 3 && (
//                       <div className="card">
//                         <div className="card-header" id="headingThree">
//                           <h5 className="mb-0">
//                             <button
//                               className="btn btn-link collapsed"
//                               type="button"
//                               data-toggle="collapse"
//                               data-target="#collapseThree"
//                               aria-expanded="false"
//                               aria-controls="collapseThree"
//                             >
//                               <span className="number">3</span> Payment
//                             </button>
//                           </h5>
//                         </div>
//                         <div
//                           id="collapseThree"
//                           className="collapse show"
//                           aria-labelledby="headingThree"
//                           data-parent="#accordionExample"
//                         >
//                           {/* <div className="checkout-step-body">
//                             <div className="payment_method-checkout">
//                               <div className="row">
//                                 <div className="col-md-12">
//                                   <h6 className="card-title">Total Amount:</h6>
//                                   <h5 className="mb-3">
//                                     <strong>₹{grandTotal}</strong>
//                                   </h5>
//                                 </div>
//                               </div>
//                               <button
//                                 onClick={this.handlePaymentSystem}
//                                 className="btn btn-danger btn-block"
//                               >
//                                 Pay Now
//                               </button>
//                             </div>
//                           </div> */}

//                           <div className="checkout-step-body">
//                             <div className="payment_method-checkout">
//                               <div className="row">
//                                 <div className="col-md-12">
//                                   <button
//                                     className="next-btn16 hover-btn"
//                                     onClick={this.handlePaymentSystem}
//                                   >
//                                     Confirm Order
//                                   </button>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>

//               <div className="col-md-4">
//                 <div className="checkout-summary">
//                   <h3>Cart Summary</h3>
//                   {cartItems.map((row, index) => (
//                     <div className="card-body pt-0 pr-0 pl-0 pb-0" key={index}>
//                       <div className="cart-list-product">
//                         <img
//                           crossOrigin="anonymous"
//                           className="img-fluid"
//                           src={row.photo}
//                           alt="cart"
//                         />
//                         <h5>{row.name}</h5>
//                         <div className="cart-radio">
//                           {row.selectedVariants &&
//                             row.selectedVariants.map((machine) => (
//                               <div
//                                 key={machine.id}
//                                 className="d-flex align-items-center justify-content-between"
//                               >
//                                 <span>
//                                   {machine.name} (₹{machine.price})
//                                 </span>
//                               </div>
//                             ))}
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                   <div className="total-checkout-group">
//                     <div className="cart-total-dil">
//                       <h4>Sub Total</h4>
//                       <span>&#x20B9;{subTotal.toFixed(2)}</span>
//                     </div>
//                   </div>
//                   <div className="total-checkout-group">
//                     <div className="cart-total-dil">
//                       <h4>Discount</h4>
//                       <span>-&#x20B9;{discount.toFixed(2)}</span>
//                     </div>
//                   </div>
//                   <div className="main-total-cart">
//                     <h2>Total</h2>
//                     <span>&#x20B9;{total.toFixed(2)}</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   cartItems: state.cart.cartItems,
// });

// export default connect(mapStateToProps, {
//   removeFromCart,
//   incrementToCart,
//   decreaseToCart,
// })(Checkout);
import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { GetUserLogin, GetOrderDetails, CartHelper } from "../../../services";
import {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
} from "../../../../store/actions/cartActions";
import Deliverydetails from "./delivery";
import Loader from "../../../../loader";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      subTotal: 0,
      discount: 0,
      deliveryCharge: 0,
      grandTotal: 0,
      email: "",
      customer: "",
      deliveryAddress: "",
      currentStep: 1,
    };
  }

  handleRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeliveryAddress = (value) => {
    this.setState({ deliveryAddress: value, currentStep: 3 }); // Move to payment step after selecting delivery address
  };

  async componentDidMount() {
    let email = sessionStorage.getItem("email");
    if (email) {
      let user = await GetUserLogin.getCustomerDetail(email);
      if (user) {
        this.setState({ customer: user.data, email: email, currentStep: 2 }); // Open delivery section after login
      }
    }

    let cart = this.props.cartItems;
    let subTotal = this.calculateSubTotal();
    let discount = subTotal > 200 ? subTotal * 0.1 : 0; // 10% discount if subtotal is greater than 200
    let deliveryCharge = subTotal * 0.05;
    let grandTotal = subTotal - discount + deliveryCharge;

    this.setState({ subTotal, discount, deliveryCharge, grandTotal });
  }


  handlePaymentSystem = async (e) => {
    e.preventDefault();
    this.setState({ isLoaded: true });

    const { customer, deliveryAddress } = this.state;
    const { cartItems } = this.props;

    if (deliveryAddress) {
        const grandTotal = this.calculateGrandTotal(cartItems);
        const products = this.prepareProducts(cartItems);
        const addressId = deliveryAddress.selectedAddress || null;

        try {
            // Step 1: Create the Razorpay order and get the order ID
            const razorpayOrderResponse = await this.createRazorpayOrder(grandTotal);

            // Check if the order creation was successful
            if (!razorpayOrderResponse) {
                this.setState({ isLoaded: false });
                return; // Exit if the order creation failed
            }

            const orderId = razorpayOrderResponse; // Extract order ID

            // Step 2: Prepare preorder data using the order ID
            const preorderData = {
                customerId: customer.id,
                orderId: orderId,
                grandTotal: grandTotal,
                products: products,
                addressId: addressId,
                deliveryAddress: deliveryAddress,
            };

            // Step 3: Create the preorder with the obtained order ID
            await this.createPreorder(preorderData);

            // Step 4: Prepare Razorpay payment options
            const options = this.getRazorpayOptions(grandTotal, customer, orderId, addressId, deliveryAddress, products);
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

            paymentObject.on("close", () => {
                this.setState({ isLoaded: false });
            });
        } catch (err) {
            console.error("Error during payment processing:", err);
            NotificationManager.error("Payment processing failed", "Error");
            this.setState({ isLoaded: false });
        }
    } else {
        NotificationManager.error("Please check address details", "Input Field");
        this.setState({ isLoaded: false });
    }
};



  calculateGrandTotal = (cartItems) => {
    const subTotal = this.calculateSubTotal(cartItems);
    const discount = subTotal > 200 ? subTotal * 0.1 : 0;
    const deliveryCharge = 0;
    return subTotal - discount + deliveryCharge;
  };


  prepareProducts = (cartItems) => {
    return cartItems.map((item) => {
      const variantIds = item.selectedVariants.map((variant) => variant.id);
      return {
        productId: item.id,
        variantIds: variantIds,
      };
    });
  };


  createPreorder = async (preorderData) => {
    return await GetOrderDetails.createPreorder(preorderData);
  };

  createRazorpayOrder = async (grandTotal) => {
    return await GetOrderDetails.RazorpayOrder(grandTotal);
};

 
getRazorpayOptions = (grandTotal, customer, orderId, addressId, deliveryAddress, products) => {
  return {
      key: "rzp_live_YJ7vOnVL1HKfgL", // Replace with your actual key
      amount: grandTotal * 100,
      currency: "INR",
      name: "JC Creations",
      description: "JC Creations",
      image: "img/footerlogo.webp",
      prefill: {
          name: customer.fullName,
          email: customer.email,
          contact: customer.phone || '',
      },
      theme: {
          color: "#F37254",
      },
      order_id: orderId, // Include the orderId in the payment options
      handler: async (response) => {
          if (response.razorpay_payment_id) {
              await this.handlePaymentCapture(
                  response.razorpay_payment_id,
                  grandTotal,
                  customer,
                  orderId, // Pass orderId to the payment capture function
                  addressId,
                  deliveryAddress,
                  products
              );
          }
      },
      modal: {
          ondismiss: () => {
              // Stop the loader if payment modal is closed
              this.setState({ isLoaded: false });
          },
      },
  };
};

  
  handlePaymentCapture = async (
    razorpayPaymentId,
    grandTotal,
    customer,
    orderId,
    addressId,
    deliveryAddress,
    products
  ) => {
    try {
      const Paymentdata = { razorpayPaymentId, grandTotal };
  
     
      try {
        await GetOrderDetails.capturePayment(Paymentdata);
      } catch (captureError) {
        console.warn("Payment capture failed, proceeding with order creation:", captureError);
      }
  
      // Step 2: Create the order
      const orderData = {
        customerId: customer.id,
        orderId: orderId,
        addressId: addressId,
        deliveryAddress: deliveryAddress,
        grandTotal: grandTotal,
        razorpayPaymentId: razorpayPaymentId,
        products: products,
      };
  
      let order = await GetOrderDetails.getOrderCreateByUser(orderData);
  
      if (order) {
        // Step 3: Send order confirmation email with order and customer details
        const confirmationData = {
            orderId: order.orderId,
            customerId: customer.id,
        };
        console.log(confirmationData)

        try {
            await GetOrderDetails.sendOrderEmail(confirmationData);
            NotificationManager.success("Successfully Ordered", "Order");
        } catch (emailError) {
            console.error("Failed to send order confirmation email:", emailError);
        }

        setTimeout(() => {
            CartHelper.emptyCart();
        }, 1000);
      } else {
        window.location.href = "/order/failed";
      }
  
    } catch (err) {
      console.error("Error during payment or order creation:", err);
      NotificationManager.error("Order creation failed", "Error");
    } finally {
      this.setState({ isLoaded: false }); // Stop the loader in both success and failure
    }
  };
  

  handleRemoveProduct = (productId) => {
    this.props.removeFromCart(productId, null);
  };

  calculateSubTotal = () => {
    return this.props.cartItems.reduce((sum, item) => {
      if (Array.isArray(item.selectedVariants)) {
        return (
          sum +
          item.selectedVariants.reduce((variantSum, variant) => {
            return (
              variantSum +
              (typeof variant.price === "number" ? variant.price : 0)
            );
          }, 0)
        );
      }
      return sum;
    }, 0);
  };

  render() {
    const { cartItems } = this.props;
    const { discount, email, customer, currentStep, isLoaded } = this.state;

    const subTotal = this.calculateSubTotal();
    const deliveryCharges = subTotal * 0.0;
    const total = subTotal - discount + deliveryCharges;

    return (
      <div>
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <a href="/">
                  <strong>
                    <span className="mdi mdi-home"></span> Home
                  </strong>
                </a>{" "}
                <span className="mdi mdi-chevron-right"></span> <a>Checkout</a>
              </div>
            </div>
          </div>
        </section>

        <section className="checkout-page section-padding">
          <div className="container">
            {isLoaded ? <Loader /> : ""}
            <div className="row">
              <div className="col-md-8">
                <div className="checkout-step">
                  <div className="accordion" id="accordionExample">
                    <div className="card checkout-step-one">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link checkout-login-bk"
                            disabled
                          >
                            <span className="number">1</span> Login{" "}
                            <span className="mdi mdi-checkbox-marked-circle-outline"></span>
                          </button>
                          <div className="_2jDL7w">
                            <div>
                              <span className="dNZmcB">
                                {customer.firstName}
                              </span>
                              <span className="_3MeY5j">{email}</span>
                            </div>
                          </div>
                        </h5>
                      </div>
                    </div>

                    {/* Step 2: Delivery Address */}
                    {currentStep >= 2 && (
                      <div className="card checkout-step-two">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <span className="number">2</span> Billing Address
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse show"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <Deliverydetails
                            onSelectDeliveryAddress={this.handleDeliveryAddress}
                          />
                        </div>
                      </div>
                    )}

                    {/* Step 3: Payment */}
                    {currentStep >= 3 && (
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <span className="number">3</span> Payment
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse show"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          {/* <div className="checkout-step-body">
                            <div className="payment_method-checkout">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6 className="card-title">Total Amount:</h6>
                                  <h5 className="mb-3">
                                    <strong>₹{grandTotal}</strong>
                                  </h5>
                                </div>
                              </div>
                              <button
                                onClick={this.handlePaymentSystem}
                                className="btn btn-danger btn-block"
                              >
                                Pay Now
                              </button>
                            </div>
                          </div> */}

                          <div className="checkout-step-body">
                            <div className="payment_method-checkout">
                              <div className="row">
                                <div className="col-md-12">
                                  <button
                                    className="next-btn16 hover-btn"
                                    onClick={this.handlePaymentSystem}
                                  >
                                    Confirm Order
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="checkout-summary">
                  <h3>Cart Summary</h3>
                  {cartItems.map((row, index) => (
                    <div className="card-body pt-0 pr-0 pl-0 pb-0" key={index}>
                      <div className="cart-list-product">
                        <img
                          crossOrigin="anonymous"
                          className="img-fluid"
                          src={row.photo}
                          alt="cart"
                        />
                        <h5>{row.name}</h5>
                        <div className="cart-radio">
                          {row.selectedVariants &&
                            row.selectedVariants.map((machine) => (
                              <div
                                key={machine.id}
                                className="d-flex align-items-center justify-content-between"
                              >
                                <span>
                                  {machine.name} (₹{machine.price})
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="total-checkout-group">
                    <div className="cart-total-dil">
                      <h4>Sub Total</h4>
                      <span>&#x20B9;{subTotal.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="total-checkout-group">
                    <div className="cart-total-dil">
                      <h4>Discount</h4>
                      <span>-&#x20B9;{discount.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="main-total-cart">
                    <h2>Total</h2>
                    <span>&#x20B9;{total.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
});

export default connect(mapStateToProps, {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
})(Checkout);

// import React, { Component } from "react";
// import { GetAddressDetails, GetUserLogin } from "../../../../services";
// import "./delivery.css";
// import { NotificationManager } from "react-notifications";

// export default class Deliverydetails extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       user: "",
//       locationList: [],
//       name: "",
//       phone: "",
//       area: "",
//       states: "",
//       showNewAddressForm: false,
//       selectedAddress: "", // State for selected address
//     };
//   }

//   async componentDidMount() {
//     let email = sessionStorage.getItem("email");
//     if (email) {
//       let value = await GetUserLogin.getCustomerDetail(email);
//       if (value) {
//         this.setState({ user: value.data });
//         this.fetchAddresses(value.data.id);
//       }
//     }
//   }

//   fetchAddresses = async (customerId) => {
//     let response = await GetAddressDetails.getAddressesByCustomerId(customerId);
//     if (response && response.success) {
//       this.setState({ locationList: response.data });
//       // If addresses are found, auto-select the first address
//       if (response.data.length > 0) {
//         const firstAddress = response.data[0];
//         this.setState({
//           selectedAddress: firstAddress.id,
//           name: firstAddress.fullname,
//           phone: firstAddress.phone,
//           area: firstAddress.area,
//           states: firstAddress.states,
//           showNewAddressForm: false, // Ensure new address form is closed
//         });
//       } else {
//         // If no addresses are found, show the new address form
//         this.setState({ showNewAddressForm: true });
//       }
//     }
//   };

//   handleChange = (e) => {
//     this.setState({ [e.target.name]: e.target.value });
//   };

//   handleAddressSelect = (address) => {
//     this.setState({
//       selectedAddress: address.id,
//       showNewAddressForm: false,
//       name: address.fullname,
//       phone: address.phone,
//       area: address.area,
//       states: address.states,
//     });
//   };

//   toggleNewAddressForm = () => {
//     this.setState((prevState) => ({
//       showNewAddressForm: !prevState.showNewAddressForm,
//       selectedAddress: "", // Reset selected address when toggling to new address form
//       name: "", // Reset name field
//       phone: "", // Reset phone field
//       states: "", // Reset states field
//       area: "", // Reset area field
//     }));
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     const { name, phone, area, states, selectedAddress, showNewAddressForm } =
//       this.state;

//     // Check if an address is selected or the new address form is being used
//     if (!selectedAddress && !showNewAddressForm) {
//       alert("Please select or add a new address.");
//       return;
//     }

//     // Check if all mandatory fields are filled
//     if (!name || !phone || !area || !states) {
//       alert("Please fill all mandatory fields.");
//       return;
//     }

//     // Create the delivery object
//     let delivery = {
//       name,
//       phone,
//       area,
//       states,
//     };

//     // If a new address is being added, include the form data in the submission
//     if (showNewAddressForm) {
//       delivery = {
//         ...delivery,
//         addressId: null, // Use null or appropriate key to indicate new address is being added
//       };
//     } else {
//       // Include selectedAddress ID when not adding a new address
//       delivery.selectedAddress = selectedAddress;
//     }
//     NotificationManager.success("Delivery adress updated");
//     // Pass the delivery object to the parent component
//     this.props.onSelectDeliveryAddress(delivery);
//   };

//   render() {
//     const {
//       name,
//       phone,
//       area,
//       states,
//       locationList,
//       showNewAddressForm,
//       selectedAddress,
//     } = this.state;

//     return (
//       <div className="card-body">
//         {locationList.length > 0 && !showNewAddressForm ? (
//           <div className="address-list">
//             {locationList.map((address, index) => (
//               <div
//                 className="address-item"
//                 key={index}
//                 onClick={() => this.handleAddressSelect(address)}
//               >
//                 <div
//                   className={`delivery-custom-checkbox ${
//                     selectedAddress === address.id ? "checked" : ""
//                   }`}
//                 ></div>
//                 <label>
//                   {address.fullname}
//                   <br />
//                   +91 {address.phone}
//                   <br />
//                   {address.area ? `${address.area}, ` : ""}
//                   {address.states ? `${address.states}` : ""}
//                 </label>
//               </div>
//             ))}
//           </div>
//         ) : (
//           // Show the new address form when no addresses are found or it is explicitly opened
//           showNewAddressForm && (
//             <form>
//               <div className="row">
//                 <div className="col-sm-6">
//                   <div className="form-group">
//                     <label className="control-label">
//                       Full Name <span className="required">*</span>
//                     </label>
//                     <input
//                       className="form-control border-form-control"
//                       type="text"
//                       name="name"
//                       value={name}
//                       onChange={this.handleChange}
//                       required // Mark as required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6">
//                   <div className="form-group">
//                     <label className="control-label">
//                       Phone <span className="required">*</span>
//                     </label>
//                     <input
//                       type="number"
//                       className="form-control border-form-control"
//                       name="phone"
//                       value={phone}
//                       onChange={this.handleChange}
//                       required // Mark as required
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-sm-6">
//                   <div className="form-group">
//                     <label className="control-label">
//                       Town / City <span className="required">*</span>
//                     </label>
//                     <input
//                       className="form-control border-form-control"
//                       type="text"
//                       name="area"
//                       value={area}
//                       onChange={this.handleChange}
//                       required // Mark as required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6">
//                   <div className="form-group">
//                     <label className="control-label">
//                       State <span className="required">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control border-form-control"
//                       name="states"
//                       value={states}
//                       onChange={this.handleChange}
//                       required // Mark as required
//                     />
//                   </div>
//                 </div>
//               </div>
//             </form>
//           )
//         )}

//         <button
//           type="button"
//           className="btn btn-secondary mb-2 btn-lg"
//           onClick={this.handleSubmit}
//         >
//           NEXT
//         </button>
//       </div>
//     );
//   }
// }
import React, { Component } from "react";
import { GetAddressDetails, GetUserLogin } from "../../../../services";
import "./delivery.css";
import { NotificationManager } from "react-notifications";

export default class Deliverydetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      locationList: [],
      name: "",
      phone: "",
      area: "",
      states: "",
      showNewAddressForm: false,
      selectedAddress: "", // State for selected address
    };
  }

  async componentDidMount() {
    let email = sessionStorage.getItem("email");
    if (email) {
      let value = await GetUserLogin.getCustomerDetail(email);
      if (value) {
        this.setState({ user: value.data });
        this.fetchAddresses(value.data.id);
      }
    }
  }

  fetchAddresses = async (customerId) => {
    let response = await GetAddressDetails.getAddressesByCustomerId(customerId);
    if (response && response.success) {
      this.setState({ locationList: response.data });
      // If addresses are found, auto-select the first address
      if (response.data.length > 0) {
        const firstAddress = response.data[0];
        this.setState({
          selectedAddress: firstAddress.id,
          name: firstAddress.fullname,
          phone: firstAddress.phone,
          area: firstAddress.area,
          states: firstAddress.states,
          showNewAddressForm: false, // Ensure new address form is closed
        }, () => {
          // Show success notification
          NotificationManager.success("Address found and selected");
          // Pass the delivery object to the parent component
          this.props.onSelectDeliveryAddress({
            name: firstAddress.fullname,
            phone: firstAddress.phone,
            area: firstAddress.area,
            states: firstAddress.states,
            selectedAddress: firstAddress.id,
          });
        });
      } else {
        // If no addresses are found, show the new address form
        this.setState({ showNewAddressForm: true });
      }
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddressSelect = (address) => {
    this.setState({
      selectedAddress: address.id,
      showNewAddressForm: false,
      name: address.fullname,
      phone: address.phone,
      area: address.area,
      states: address.states,
    }, () => {
      // Show success notification
      NotificationManager.success("Address selected");
      // Pass the delivery object to the parent component
      this.props.onSelectDeliveryAddress({
        name: address.fullname,
        phone: address.phone,
        area: address.area,
        states: address.states,
        selectedAddress: address.id,
      });
    });
  };

  toggleNewAddressForm = () => {
    this.setState((prevState) => ({
      showNewAddressForm: !prevState.showNewAddressForm,
      selectedAddress: "", // Reset selected address when toggling to new address form
      name: "", // Reset name field
      phone: "", // Reset phone field
      states: "", // Reset states field
      area: "", // Reset area field
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, phone, area, states, selectedAddress, showNewAddressForm } = this.state;

    // Check if an address is selected or the new address form is being used
    if (!selectedAddress && !showNewAddressForm) {
      alert("Please select or add a new address.");
      return;
    }

    // Check if all mandatory fields are filled
    if (!name || !phone || !area || !states) {
      alert("Please fill all mandatory fields.");
      return;
    }

    // Create the delivery object
    let delivery = {
      name,
      phone,
      area,
      states,
    };

    // If a new address is being added, include the form data in the submission
    if (showNewAddressForm) {
      delivery = {
        ...delivery,
        addressId: null, // Use null or appropriate key to indicate new address is being added
      };
    } else {
      // Include selectedAddress ID when not adding a new address
      delivery.selectedAddress = selectedAddress;
    }
    NotificationManager.success("Delivery address updated");
    // Pass the delivery object to the parent component
    this.props.onSelectDeliveryAddress(delivery);
  };

  render() {
    const {
      name,
      phone,
      area,
      states,
      locationList,
      showNewAddressForm,
      selectedAddress,
    } = this.state;

    return (
      <div className="card-body">
        {locationList.length > 0 && !showNewAddressForm ? (
          <div className="address-list">
            {locationList.map((address, index) => (
              <div
                className="address-item"
                key={index}
                onClick={() => this.handleAddressSelect(address)}
              >
                <div
                  className={`delivery-custom-checkbox ${
                    selectedAddress === address.id ? "checked" : ""
                  }`}
                ></div>
                <label>
                  {address.fullname}
                  <br />
                  +91 {address.phone}
                  <br />
                  {address.area ? `${address.area}, ` : ""}
                  {address.states ? `${address.states}` : ""}
                </label>
              </div>
            ))}
          </div>
        ) : (
          // Show the new address form when no addresses are found or it is explicitly opened
          showNewAddressForm && (
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="control-label">
                      Full Name <span className="required">*</span>
                    </label>
                    <input
                      className="form-control border-form-control"
                      type="text"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                      required // Mark as required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="control-label">
                      Phone <span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control border-form-control"
                      name="phone"
                      value={phone}
                      onChange={this.handleChange}
                      required // Mark as required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="control-label">
                      Town / City <span className="required">*</span>
                    </label>
                    <input
                      className="form-control border-form-control"
                      type="text"
                      name="area"
                      value={area}
                      onChange={this.handleChange}
                      required // Mark as required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="control-label">
                      State <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control border-form-control"
                      name="states"
                      value={states}
                      onChange={this.handleChange}
                      required // Mark as required
                    />
                  </div>
                </div>
              </div>
            </form>
          )
        )}

        {/* Only show the NEXT button when no addresses are found */}
        {locationList.length === 0 && (
          <button
            type="button"
            className="btn btn-secondary mb-2 btn-lg"
            onClick={this.handleSubmit}
          >
            NEXT
          </button>
        )}
      </div>
    );
  }
}

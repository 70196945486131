import api from '../../../../app/ApiConfig';
import { Apis } from '../../../../config';
import { NotificationManager } from 'react-notifications';

const getOrderCreateByUser = async (data) => {
    try {
        let result = await api.post(Apis.GetOrderCreateByUser,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const capturePayment = async (data) => {
    try {
        let result = await api.post(Apis.GetPaymentCapture,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const sendOrderEmail = async (data) => {
    try {
        let result = await api.post(Apis.GetOrderEmail,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const RazorpayOrder = async (grandTotal) => {
    try {
        // Wrap grandTotal in an object
        const data = { grandTotal };
        let result = await api.post(Apis.GetRazorpayOrder, data); // Send the data object

        // Check if the response indicates an error
        if (result.data && result.data.success === false) {
            NotificationManager.error(result.data.message || "An error occurred while creating the order.");
            return null;
        }

        // Ensure the response contains the expected structure
        if (!result.data || !result.data.orderId) {
            NotificationManager.error("Unexpected response from Razorpay. Order ID is missing.");
            return null; // Return null if orderId is not present
        }

        // Return the orderId from the response
        return result.data.orderId;
    } catch (error) {
        console.error("Error creating Razorpay order:", error);
        NotificationManager.error("An error occurred while creating the Razorpay order. Please try again.");
        return null; // Return null in case of an exception
    }
};


const Checkorders = async (data) => {
    try {
        let result = await api.post(Apis.GetCheckOrders,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



const createPreorder = async (data) => {
    try {
        let result = await api.post(Apis.GetPreOrderCreate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getOrderByUser = async (id) => {
    try {
        let result = await api.get(`${Apis.GetOrderByUser}?id=${id}`);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getPaymentValue = async (data) => {
    try {
        let result = await api.post(Apis.GetPaymentValue,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getPaymentVerification = async () => {
    try {
        let result = await api.post(Apis.GetPaymentVerification);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getPaymentOrderList = async (data) => {
    try {
        let result = await api.post(Apis.GetPaymentOrderList,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getOrderDownload = async (filename) => {
    try {
        let result = await api.get(`${Apis.GetOrderDownload}?filename=${filename}`, {
            responseType: 'blob' // Ensure the response is a binary blob
        });

        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }

        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    getOrderCreateByUser,
    capturePayment,
    getOrderByUser,
    getPaymentVerification,
    getPaymentValue,
    getPaymentOrderList,
    getOrderDownload,
    createPreorder,
    Checkorders,
    RazorpayOrder,
    sendOrderEmail
};
// import React, { Component } from "react";
// import { Link, withRouter } from "react-router-dom";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { NotificationManager } from "react-notifications";
// import {
//   GetProductDetails,
//   GetUserLogin,
//   GetWishListDetails,
// } from "../../../services"; // example path
// import "./styles.css";

// class TagProduct extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       productlist: [],
//       isLoaded: false,
//       wishlist: [], 
//       custId: null, 
//       currentPage: 1,
//       itemsPerPage: 18, 
//       visiblePages: 8, 
//       pageGroupStart: 1, 
//       isMobile: window.innerWidth <= 768, 
//       searchText: "", 
//     };
//   }

//   async componentDidMount() {
//     const { tag } = this.props.match.params;
//     const searchParams = new URLSearchParams(this.props.location.search);
//     const page = parseInt(searchParams.get("page")) || 1;
  
//     this.setState({ currentPage: page }); // Set page from URL or default to 1
//     await this.fetchProducts(tag);
  
//     let email = sessionStorage.getItem("email");
//     if (email) {
//       let user = await GetUserLogin.getCustomerDetail(email);
//       if (user) {
//         this.setState({ custId: user.data.id });
//       }
//     }
  
//     window.addEventListener("resize", this.updateIsMobile);
//   }
  


  
//   fetchProducts = async (tag) => {
//     this.setState({ isLoaded: false });
  
//     const response = await GetProductDetails.getProductByTagSearch(tag);
//     const uniqueProducts = response ? response.products : [];
//     const uniqueProductIds = new Set();
//     const filteredProducts = uniqueProducts.filter((product) => {
//       if (!uniqueProductIds.has(product.id)) {
//         uniqueProductIds.add(product.id);
//         return true;
//       }
//       return false;
//     });
  
//     this.setState({
//       productlist: filteredProducts,
//       isLoaded: true,
//     });
//   };
  

//   handleSearchChange = (event) => {
//     this.setState({ searchText: event.target.value }); // Update search text in state
//   };

//   handleSearchSubmit = async (event) => {
//     event.preventDefault();
//     const { searchText } = this.state;
//     const tag = searchText.trim();
  
//     if (tag) {
//       await this.fetchProducts(tag);
//       this.setState({ currentPage: 1, pageGroupStart: 1 });
//       this.props.history.push(`/tagsearch/${tag}?page=1`);
//     } else {
//       NotificationManager.error("Please enter a search term.");
//     }
//   };
  

//   componentWillUnmount() {
//     // Remove event listener for window resize when the component unmounts
//     window.removeEventListener("resize", this.updateIsMobile);
//   }

//   updateIsMobile = () => {
//     this.setState({ isMobile: window.innerWidth <= 768 });
//   };

//   toggleWishlist = (productId) => {
//     const { wishlist } = this.state;
//     const isProductInWishlist = wishlist.includes(productId);

//     this.setState({
//       wishlist: isProductInWishlist
//         ? wishlist.filter((id) => id !== productId)
//         : [...wishlist, productId],
//     });
//   };

//   handleAddToWishlistClick = async (productId) => {
//     const { custId } = this.state;

//     if (!custId) {
//       NotificationManager.error("Please log in to add items to your wishlist.");
//       return;
//     }

//     const data = { custId: custId, productId: productId };

//     try {
//       let result = await GetWishListDetails.addWishlistItem(data); // Call service function
//       if (result) {
//         NotificationManager.success("Added to wishlist!");
//         this.toggleWishlist(productId); // Toggle wishlist after adding
//       } else {
//         NotificationManager.error("Product is already in your wishlist.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       NotificationManager.error(
//         "An error occurred while adding to the wishlist."
//       );
//     }
//   };

//   // Pagination Methods
//   handlePageChange = (pageNumber) => {
//     this.setState({ currentPage: pageNumber }, () => {
//       this.props.history.push(`/tagsearch/${this.props.match.params.tag}?page=${pageNumber}`);
//     });
//     window.scrollTo(0, 0);
//   };
  

//   // Handle next page group
//   handleNextPageGroup = () => {
//     const { pageGroupStart, visiblePages, productlist, itemsPerPage } =
//       this.state;
//     const totalPages = Math.ceil(productlist.length / itemsPerPage);

//     // Calculate the next group's start index
//     if (pageGroupStart + visiblePages <= totalPages) {
//       this.setState({ pageGroupStart: pageGroupStart + visiblePages });
//       window.scrollTo(0, 0);
//     }
//   };

//   // Handle previous page group
//   handlePreviousPageGroup = () => {
//     const { pageGroupStart, visiblePages } = this.state;

//     // Calculate the previous group's start index
//     if (pageGroupStart - visiblePages > 0) {
//       this.setState({ pageGroupStart: pageGroupStart - visiblePages });
//       window.scrollTo(0, 0);
//     }
//   };


//   handleSharePage = () => {
//     const currentUrl = window.location.href; // Get the full current URL of the page
  
//     // Check if Web Share API is supported
//     if (navigator.share) {
//       navigator
//         .share({
//           title: 'Check out this page!', // Title of the shared page
//           text: 'Here is a page you might be interested in.', // Optional message
//           url: currentUrl, // The full URL of the page to share
//         })
//         .then(() => console.log('Page shared successfully!'))
//         .catch((error) => console.log('Error sharing page:', error));
//     } else {
//       // Fallback for browsers that don't support the Web Share API
//       alert(`Copy this link to share: ${currentUrl}`);
//       console.log('Share this URL:', currentUrl);
//     }
//   };
  

//   handleProductShare = (productId) => {
//     const shareUrl = `https://jc-creations.co.in/p/${productId}`;
//     if (navigator.share) {
//       navigator
//         .share({
//           title: "Check out this product!",
//           url: shareUrl,
//         })
//         .then(() => console.log("Share successful"))
//         .catch((error) => console.log("Sharing failed", error));
//     } else {
//       // Fallback for browsers that do not support the Web Share API
//       console.log("Share this URL:", shareUrl);
//       alert("Copy this URL: " + shareUrl);
//     }
//   };

//   render() {
//     const {
//       productlist,
//       isLoaded,
//       wishlist,
//       currentPage,
//       itemsPerPage,
//       visiblePages,
//       pageGroupStart,
//       isMobile,
//       searchText,
//     } = this.state;

//     // Calculate the number of visible pages based on screen size
//     const maxVisiblePages =  visiblePages;

//     // Calculate the index of the first and last product for the current page
//     const indexOfLastProduct = currentPage * itemsPerPage;
//     const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
//     const currentProducts = productlist.slice(
//       indexOfFirstProduct,
//       indexOfLastProduct
//     ); // Slice the products based on the current page

//     // Calculate total pages
//     const totalPages = Math.ceil(productlist.length / itemsPerPage);

//     // Determine the page numbers to show
//     const pageNumbers = [];
//     for (
//       let i = pageGroupStart;
//       i < Math.min(pageGroupStart + maxVisiblePages, totalPages + 1);
//       i++
//     ) {
//       pageNumbers.push(i);
//     }

//     const { tag } = this.props.match.params;

//     return (
//       <div>
//         <div className="search-section145">
//           <div className="search-text-center">
//             <div
//               className="search-search-container"
//               style={{ position: "relative" }}
//             >
//               <form
//                 onSubmit={this.handleSearchSubmit}
//                 className="search-search-form"
//               >
//                 <input
//                   type="text"
//                   placeholder="Search with Category, Tag & Product Name"
//                   className="search-search-box"
//                   value={searchText}
//                   onChange={this.handleSearchChange}
//                 />
//                 <button type="submit" className="search-search-btn">
//                   Search
//                 </button>
//               </form>
//               <button onClick={this.handleSharePage} className="search-search-btn">
//                   Share
//                 </button>            
//             </div>
//           </div>
//         </div>
//         <section className="product-items-slider section-padding">
//           <div className="container tagheader" id="header-category-bk">
//             <div className="section-header text-center tag-section">
//               <h2>
//                 Search Results for -{" "}
//                 <span
//                   style={{
//                     fontSize: "24px",
//                     color: "darkblue",
//                     textTransform: "capitalize",
//                   }}
//                 >
//                   {tag}
//                 </span>
              
//               </h2> 
//               <br />
//             </div>
//             <div className="row justify-content-center">
//               {!isLoaded ? (
//                 <div className="progress-bar-bk">
//                   <CircularProgress color="secondary" />
//                 </div>
//               ) : (
//                 currentProducts.map((row, index) => (
//                   <div
//                     key={index}
//                     className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
//                   >
//                     <div
//                       className="product"
//                       style={{ backgroundColor: "black" }}
//                     >
//                       <Link to={`/p/${row.id}`}>
//                         <div className="product-header">
//                           <img
//                             loading="lazy" // Lazy load images
//                             crossOrigin="anonymous"
//                             className="img-fluid"
//                             src={row.photo}
//                             alt={row.name}
//                             width="100%"
//                             height="100%"
//                             style={{objectFit: "contain"}}
                            
//                           />
//                         </div>
//                         <div className="product-body">
//                           <h6 style={{ display: "none" }}>
//                             <strong>
//                               <span className="mdi mdi-approval" /> Code
//                             </strong>{" "}
//                             - {row.slug}
//                           </h6>
//                         </div>
//                       </Link>
//                       <div className="product-footer">
//                         <button
//                           type="button"
//                           className="btn btn-secondary btn-sm float-right"
//                           onClick={() => this.handleProductShare(row.id)}
//                         >
//                           <i className="mdi mdi-share" /> Share
//                         </button>
//                         <i
//                           className={`mdi ${
//                             wishlist.includes(row.id)
//                               ? "mdi-heart"
//                               : "mdi-heart-outline"
//                           } wishlist-icon`}
//                           onClick={() => this.handleAddToWishlistClick(row.id)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               )}
//             </div>
//           </div>
//           {/* Pagination Controls */}
//           <div className="pagination-controls">
//             <button
//               className="arrow-button"
//               onClick={this.handlePreviousPageGroup}
//               disabled={pageGroupStart === 1}
//             >
//               &lt;
//             </button>
//             {pageNumbers.map((number) => (
//               <button
//                 key={number}
//                 className={`page-button ${
//                   currentPage === number ? "active" : ""
//                 }`}
//                 onClick={() => this.handlePageChange(number)}
//               >
//                 {number}
//               </button>
//             ))}
//             <button
//               className="arrow-button"
//               onClick={this.handleNextPageGroup}
//               disabled={pageGroupStart + maxVisiblePages > totalPages}
//             >
//               &gt;
//             </button>
//           </div>
//         </section>
//       </div>
//     );
//   }
// }

// export default withRouter(TagProduct);

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationManager } from "react-notifications";
import {
  GetProductDetails,
  GetUserLogin,
  GetWishListDetails,
} from "../../../services"; // example path
import "./styles.css";

class TagProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productlist: [],
      isLoaded: false,
      wishlist: [], 
      custId: null, 
      currentPage: 1,
      itemsPerPage: 18, 
      visiblePages: 8, 
      pageGroupStart: 1, 
      isMobile: window.innerWidth <= 768, 
      searchText: "", 
    };
  }

  async componentDidMount() {
    const { tag } = this.props.match.params;
    const searchParams = new URLSearchParams(this.props.location.search);
    const page = parseInt(searchParams.get("page")) || 1;
  
    this.setState({ currentPage: page }); // Set page from URL or default to 1
    await this.fetchProducts(tag);
  
    let email = sessionStorage.getItem("email");
    if (email) {
      let user = await GetUserLogin.getCustomerDetail(email);
      if (user) {
        this.setState({ custId: user.data.id });
      }
    }
  
    window.addEventListener("resize", this.updateIsMobile);
  }
  


  
  fetchProducts = async (tag) => {
    this.setState({ isLoaded: false });
  
    const response = await GetProductDetails.getProductByTagSearch(tag);
    const uniqueProducts = response ? response.products : [];
    const uniqueProductIds = new Set();
    const filteredProducts = uniqueProducts.filter((product) => {
      if (!uniqueProductIds.has(product.id)) {
        uniqueProductIds.add(product.id);
        return true;
      }
      return false;
    });
  
    this.setState({
      productlist: filteredProducts,
      isLoaded: true,
    });
  };
  

  handleSearchChange = (event) => {
    this.setState({ searchText: event.target.value }); // Update search text in state
  };

  handleSearchSubmit = async (event) => {
    event.preventDefault();
    const { searchText } = this.state;
    const tag = searchText.trim();
  
    if (tag) {
      await this.fetchProducts(tag);
      this.setState({ currentPage: 1, pageGroupStart: 1 });
      this.props.history.push(`/tagsearch/${tag}?page=1`);
    } else {
      NotificationManager.error("Please enter a search term.");
    }
  };
  

  componentWillUnmount() {
    // Remove event listener for window resize when the component unmounts
    window.removeEventListener("resize", this.updateIsMobile);
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  toggleWishlist = (productId) => {
    const { wishlist } = this.state;
    const isProductInWishlist = wishlist.includes(productId);

    this.setState({
      wishlist: isProductInWishlist
        ? wishlist.filter((id) => id !== productId)
        : [...wishlist, productId],
    });
  };

  handleAddToWishlistClick = async (productId) => {
    const { custId } = this.state;

    if (!custId) {
      NotificationManager.error("Please log in to add items to your wishlist.");
      return;
    }

    const data = { custId: custId, productId: productId };

    try {
      let result = await GetWishListDetails.addWishlistItem(data); // Call service function
      if (result) {
        NotificationManager.success("Added to wishlist!");
        this.toggleWishlist(productId); // Toggle wishlist after adding
      } else {
        NotificationManager.error("Product is already in your wishlist.");
      }
    } catch (error) {
      console.error("Error:", error);
      NotificationManager.error(
        "An error occurred while adding to the wishlist."
      );
    }
  };

  // Pagination Methods
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.props.history.push(`/tagsearch/${this.props.match.params.tag}?page=${pageNumber}`);
    });
    window.scrollTo(0, 0);
  };
  

  // Handle next page group
  handleNextPageGroup = () => {
    const { pageGroupStart, visiblePages, productlist, itemsPerPage } =
      this.state;
    const totalPages = Math.ceil(productlist.length / itemsPerPage);

    // Calculate the next group's start index
    if (pageGroupStart + visiblePages <= totalPages) {
      this.setState({ pageGroupStart: pageGroupStart + visiblePages });
      window.scrollTo(0, 0);
    }
  };

  // Handle previous page group
  handlePreviousPageGroup = () => {
    const { pageGroupStart, visiblePages } = this.state;

    // Calculate the previous group's start index
    if (pageGroupStart - visiblePages > 0) {
      this.setState({ pageGroupStart: pageGroupStart - visiblePages });
      window.scrollTo(0, 0);
    }
  };

  handleGoBack = () => {
    window.history.back(); // Go back to the previous page
  };

  handleSharePage = () => {
    const currentUrl = window.location.href; // Get the full current URL of the page
  
    // Check if Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this page!', // Title of the shared page
          text: 'Here is a page you might be interested in.', // Optional message
          url: currentUrl, // The full URL of the page to share
        })
        .then(() => console.log('Page shared successfully!'))
        .catch((error) => console.log('Error sharing page:', error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      alert(`Copy this link to share: ${currentUrl}`);
      console.log('Share this URL:', currentUrl);
    }
  };
  

  handleProductShare = (productId) => {
    const shareUrl = `https://jc-creations.co.in/p/${productId}`;
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this product!",
          url: shareUrl,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.log("Share this URL:", shareUrl);
      alert("Copy this URL: " + shareUrl);
    }
  };

  render() {
    const {
      productlist,
      isLoaded,
      wishlist,
      currentPage,
      itemsPerPage,
      visiblePages,
      pageGroupStart,
      isMobile,
      searchText,
    } = this.state;

    // Calculate the number of visible pages based on screen size
    const maxVisiblePages =  visiblePages;

    // Calculate the index of the first and last product for the current page
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentProducts = productlist.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    ); // Slice the products based on the current page

    // Calculate total pages
    const totalPages = Math.ceil(productlist.length / itemsPerPage);

    // Determine the page numbers to show
    const pageNumbers = [];
    for (
      let i = pageGroupStart;
      i < Math.min(pageGroupStart + maxVisiblePages, totalPages + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    const { tag } = this.props.match.params;

    return (
      <div>
        <div className="search-section145">
          <div className="search-text-center">
            <div
              className="search-search-container"
              style={{ position: "relative" }}
            >
              <form
                onSubmit={this.handleSearchSubmit}
                className="search-search-form"
              >
                <input
                  type="text"
                  placeholder="Search with Category, Tag & Product Name"
                  className="search-search-box"
                  value={searchText}
                  onChange={this.handleSearchChange}
                />
                <button type="submit" className="search-search-btn">
                  Search
                </button>
              </form>
              {/* <button onClick={this.handleSharePage} className="search-search-btn">
                  Share
                </button>             */}
            </div>
          </div>
        </div>
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
  <div className="container">
    <div className="row align-items-center justify-content-between">
      <div className="col-8 col-md-6 d-flex align-items-center">
        <a href="/">
          <strong>
            <span className="mdi mdi-home" /> Home
          </strong>
        </a>
        <span className="mdi mdi-chevron-right" />{" "}
        <a href="#"> Search Product</a>
      </div>
      <div className="col-4 col-md-3 text-md-end d-flex justify-content-end">
        <button onClick={this.handleSharePage} className="search-search-btn">
          Share
        </button>
        <div style={{ width: "16px" }} /> {/* Adjust width as needed */}
        <Button
          variant="contained"
          className="back-btn goback"
          onClick={() => this.props.history.goBack()}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ marginRight: "5px" }}
          />{" "}
          Back
        </Button>
      </div>
    </div>
  </div>
</section>

        <section className="product-items-slider section-padding">
          <div className="container tagheader" id="header-category-bk">
            <div className="section-header text-center tag-section">
              <h2>
                Search Results for -{" "}
                <span
                  style={{
                    fontSize: "24px",
                    color: "darkblue",
                    textTransform: "capitalize",
                  }}
                >
                  {tag}
                </span>
              
              </h2> 
              <br />
            </div>
            <div className="row justify-content-center">
              {!isLoaded ? (
                <div className="progress-bar-bk">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                currentProducts.map((row, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                  >
                    <div
                      className="product"
                      style={{ backgroundColor: "black" }}
                    >
                      <Link to={`/p/${row.id}`}>
                        <div className="product-header">
                          <img
                            loading="lazy" // Lazy load images
                            crossOrigin="anonymous"
                            className="img-fluid"
                            src={row.photo}
                            alt={row.name}
                            width="100%"
                            height="100%"
                            style={{objectFit: "contain"}}
                            
                          />
                        </div>
                        <div className="product-body">
                          <h6 style={{ display: "none" }}>
                            <strong>
                              <span className="mdi mdi-approval" /> Code
                            </strong>{" "}
                            - {row.slug}
                          </h6>
                        </div>
                      </Link>
                      <div className="product-footer">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm float-right"
                          onClick={() => this.handleProductShare(row.id)}
                        >
                          <i className="mdi mdi-share" /> Share
                        </button>
                        <i
                          className={`mdi ${
                            wishlist.includes(row.id)
                              ? "mdi-heart"
                              : "mdi-heart-outline"
                          } wishlist-icon`}
                          onClick={() => this.handleAddToWishlistClick(row.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button
              className="arrow-button"
              onClick={this.handlePreviousPageGroup}
              disabled={pageGroupStart === 1}
            >
              &lt;
            </button>
            {pageNumbers.map((number) => (
              <button
                key={number}
                className={`page-button ${
                  currentPage === number ? "active" : ""
                }`}
                onClick={() => this.handlePageChange(number)}
              >
                {number}
              </button>
            ))}
            <button
              className="arrow-button"
              onClick={this.handleNextPageGroup}
              disabled={pageGroupStart + maxVisiblePages > totalPages}
            >
              &gt;
            </button>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(TagProduct);
